/*! @import */
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=IBM+Plex+Mono:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f167; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background: rgb(251 146 60); /* Color of the thumb */
}

body {
  color: rgb(214, 219, 220);
  background: #01060ae8;
}
